<template>
  <div>
    <v-row>
      <v-col class="text-h6" cols="6">
        <span class="text-h6"> Skills: </span>
        <ol>
          <li v-for="t in testSkills" :key="t">
            {{ t }}
          </li>
        </ol>
      </v-col>
      <v-col>
        <div class="text-h6">Anti-cheating Settings</div>
        <ul class="text-body-1">
          <li v-for="t in antiCheats" :key="t">
            {{ t }}
          </li>
        </ul>
      </v-col>
    </v-row>
    <v-row v-if="testByUrlEnabled">
      <v-col cols="12" class="d-flex justify-space-between">
        <div>
          <span class="text-h6"> Test Invite URL: </span>
          <a class="text-body-1">
            {{ testUrl }}
          </a>
        </div>
        <v-btn large text outlined @click="copyTestUrlToClipboard">
          <v-icon left color="success">
            {{ mdiClipboard }}
          </v-icon>
          <span class="success--text"> Copy URL </span>
        </v-btn>
      </v-col>
    </v-row>
    <v-row v-if="testByUrlEnabled">
      <v-col cols="12">
        <div>
          <span class="text-h6"> Test Invite URL Expiry: </span>
          <span class="text-h6">
            {{ testUrlExpiry }}
          </span>
        </div>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import { mapGetters, mapState } from "vuex";
import { mdiClipboard } from "@mdi/js";
export default {
  data() {
    return {
      mdiClipboard,
    };
  },
  props: {},
  computed: {
    ...mapState("recruiter", [
      "assessment",
      "assessmentView",
      "fetchAssessmentStatus",
    ]),
    ...mapGetters("recruiter", ["cheatingPrettyMessage"]),
    testSkills() {
      if (this.fetchAssessmentStatus && this.assessment) {
        const skillIds = this.assessment
          ?.getDataView()
          ?.getTestView()
          ?.getSkillsList();
        const childPreviews = this.assessmentView?.getChildPreviewsList();
        const skillsText = [];
        childPreviews.forEach((p) => {
          if (
            skillIds.includes(p?.getContentPreview()?.getId()) &&
            p?.getContentPreview()?.getContentType() === 7
          ) {
            skillsText.push(p?.getContentPreview()?.getTitle());
          }
        });
        return skillsText;
      }
    },
    antiCheats() {
      if (this.fetchAssessmentStatus && this.assessment) {
        const enabledAC = this.assessment
          ?.getMetaData()
          ?.getTestMeta()
          ?.getAntiCheatingList();
        const msgs = [];
        enabledAC.forEach((cht) => {
          msgs.push(this.cheatingPrettyMessage[cht.getAntiCheatingSettings()]);
        });

        return msgs;
      }
    },
    testUrl() {
      if (this.fetchAssessmentStatus && this.assessment)
        return `https://codedrills.io/signup/${this.assessment.getUrl()}/testInvite`;
    },
    testByUrlEnabled() {
      if (this.fetchAssessmentStatus && this.assessment) {
        return this.assessment
          ?.getMetaData()
          ?.getTestMeta()
          ?.getTestByUrlEnabled();
      }
    },
    testUrlExpiry() {
      if (this.fetchAssessmentStatus && this.assessment) {
        return new Date(
          this.assessment?.getMetaData()?.getTestMeta()?.getTestUrlExpiry()
        ).toDateString();
      }
    },
  },
  methods: {
    async copyTestUrlToClipboard() {
      try {
        await navigator.clipboard.writeText(this.testUrl);
        this.$store.dispatch("notifs/addNotif", {
          text: `Copied URL to clipboard!`,
          type: "success",
        });
      } catch (err) {
        this.$store.dispatch("notifs/addNotif", {
          text: `Erropr copying URL, try again!`,
          type: "error",
        });
      }
    },
  },
};
</script>
