<template>
  <v-card
    outlined
    rounded="lg"
    elevation="0"
    :loading="`${fetchAssessmentStatus}`"
  >
    <v-card-title class="text-h5 font-weight-bold">
      <v-btn class="px-n3" color="#122333" @click="handleBack">
        <v-icon color="white">
          {{ mdiArrowLeft }}
        </v-icon>
      </v-btn>
      <div class="ml-5">
        {{ assessment?.getTitle() ?? "Loading ..." }}
      </div>
    </v-card-title>
    <v-card-text class="pa-0">
      <div class="mb-8 px-5">
        <v-expansion-panels v-model="infoPanel">
          <v-expansion-panel>
            <v-expansion-panel-header class="text-h6">
              Test Info
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <AssessmentDetails v-if="fetchAssessmentStatus > 0" />
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </div>
    <candidates
      :displayCandidates="displayCandidates"
      :candidateTableLoading="candidateTableLoading"
      tableType="TEST"
      @deleteMultipleCandidates="toDeleteEmails => deleteMultipleCandidates(toDeleteEmails)"
      @handleCandidate="row => handleCandidate(row)"
      @returnToAllAssessment="returnToAllAssessment"
      @removeCandidate="item => removeCandidate(item)"
    ></candidates>
    </v-card-text>
  </v-card>
</template>

<script>
import { mdiCloudUpload, mdiArrowLeft, mdiTrashCan, mdiMagnify } from "@mdi/js";
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
import AssessmentDetails from "@/components/recruiter/AssessmentDetails.vue";
import { ApiCallStatus } from "@/utils/api";
import SmallConfirmation from "@/components/assessment/SmallConfirmation.vue";
import Candidates from "@/components/recruiter/Candidates.vue";

export default {
  data() {
    return {
      infoPanel: 0,
      // candidateSelection: [],
      mdiArrowLeft,
      mdiMagnify,
      displayCandidates: [],
      candidateTableLoading: true,
    };
  },
  props: {},
  computed: {
    ...mapState("recruiter", [
      "assessment",
      "allCandidates",
      "assessmentView",
      "fetchCandidateStatus",
      "fetchAssessmentStatus",
    ]),
    ...mapGetters("recruiter", [
      "testStatusPrettyMessage",
      "testStatusColor",
    ]),
    currentAssessmentUrl() {
      return this.assessment?.getUrl();
    },
  },
  methods: {
    ...mapActions("recruiter", [
      "fetchAssessment",
      "fetchCandidatesForTest",
      "deleteCandidates",
      "updateCandidateStage",
      "updateCandidateRating",
    ]),
    ...mapMutations("recruiter", [
      "setCandidate",
      "setAssessment",
      "setAssessmentView",
    ]),
    returnToAllAssessment() {
      this.populateDetails();
    },
    removeSelection(type, item) {
      if (type === "STATUS") {
        this.statusSelected = [
          ...this.statusSelected.filter((v) => v !== item.value),
        ];
      } else if (type === "RATING") {
        this.ratingsSelected = [
          ...this.ratingsSelected.filter((v) => v !== item.value),
        ];
      } else if (type === "STAGE") {
        this.stageSelected = [
          ...this.stageSelected.filter((v) => v !== item.value),
        ];
      } else {
      }
    },
    candidateRatingUpdate({ id, email, rating }) {
      // @TODO: Pending, event propagation wasnt stopping properly
      console.log(`candidateRatingUpdate`, { id, email, rating });
    },
    candidateStageUpdate({ id, stage }) {
      console.log(`candidateStageUpdate`, { id, stage });
      this.updateCandidateStage({
        testId: this.assessment?.getId(),
        cid: id,
        stage: stage,
      })
        .then((r) => {
          this.$store.dispatch("notifs/addNotif", {
            text: `Updated Successfully!`,
            type: "success",
            duration: 1000,
          });
          this.populateDetails();
        })
        .catch((err) => {
          this.$store.dispatch("notifs/addNotif", {
            text: `Error! Please refresh and try again!`,
            type: "error",
          });
        });
    },
    handleCandidate(row) {
      console.log("Emitted Candidate is ", row)
      this.allCandidates.forEach((cd) => {
        if (cd.getId() === row.id) {
          this.setCandidate(cd);
          this.$router.push(
            `/assessments/${this.currentAssessmentUrl}/${row.id}`
          );
        }
      });
    },
    handleBack() {
        this.$router.push("/assessments");
    },
    async removeCandidate(cand) {
      this.deleteCandidates({
        testId: this.assessment?.getId(),
        emailsList: [cand.email],
      })
        .then((r) => {
          this.$store.dispatch("notifs/addNotif", {
            text: "Candidate Deleted Successfully",
            type: "success",
          });
          this.populateDetails();
        })
        .catch((e) => {
          this.$store.dispatch("notifs/addNotif", {
            text: e.message || "Error deleting candidate, please try again!",
            type: "error",
          });
          this.populateDetails();
        });
    },
    async deleteMultipleCandidates(toDeleteEmails) {
      console.log("Deleting ... in assessment ....", toDeleteEmails);
      this.deleteCandidates({
          testId: this.assessment?.getId(),
          emailsList: toDeleteEmails,
        })
          .then((r) => {
            this.$store.dispatch("notifs/addNotif", {
              text: "Candidates Deleted Successfully",
              type: "success",
            });
            this.populateDetails();
          })
          .catch((e) => {
            this.$store.dispatch("notifs/addNotif", {
              text: e.message || "Error deleting candidates, please try again!",
              type: "error",
            });
            this.populateDetails();
          });
    },
    populateDetails() {
      this.fetchAssessment({ url: this.$route.params.url }).then((res) => {
        this.fetchCandidatesForTest({ id: this.assessment?.getId() })
          .then((r) => {
            this.displayCandidates = [];
            this.allCandidates.forEach((cd) => {
              // do not want unknown stage
              let stage = cd?.getStage();
              if (stage == 0) stage = 1;
              this.displayCandidates.push({
                id: cd?.getId(),
                name: cd?.getName(),
                email: cd?.getEmail(),
                stage,
                rating: cd?.getRating(),
                status: this.testStatusPrettyMessage[cd?.getTestStatus()],
                statusInt: cd?.getTestStatus(),
                statusColor: this.testStatusColor[cd?.getTestStatus()],
              });
            });
          })
          .catch((err) => {
            this.$store.dispatch("notifs/addNotif", {
              text: err.message || "Error Loading Candidates, please refresh!",
              type: "error",
            });
          });
      });
    },
  },
  created() {
    this.populateDetails();
  },
  components: { AssessmentDetails, SmallConfirmation, Candidates },
  watch: {
    fetchCandidateStatus() {
      if (this.fetchCandidateStatus === ApiCallStatus.SUCCESS) {
        this.candidateTableLoading = false;
      } else if (this.fetchCandidateStatus === ApiCallStatus.ERROR) {
        this.candidateTableLoading = true;
        this.$store.dispatch("notifs/addNotif", {
          text: "Error loading assessment, please reload!",
          type: "error",
        });
      } else {
        this.candidateTableLoading = true;
      }
    },
    //@TODO: fix this 
    // candidateSelection() {
    //   console.log(`candidateSelection`, this.candidateSelection);
    // },
  },
};
</script>
<style scoped>
tr {
  cursor: pointer;
}
</style>
