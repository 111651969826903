var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('v-row', [_c('v-col', {
    staticClass: "text-h6",
    attrs: {
      "cols": "6"
    }
  }, [_c('span', {
    staticClass: "text-h6"
  }, [_vm._v(" Skills: ")]), _c('ol', _vm._l(_vm.testSkills, function (t) {
    return _c('li', {
      key: t
    }, [_vm._v(" " + _vm._s(t) + " ")]);
  }), 0)]), _c('v-col', [_c('div', {
    staticClass: "text-h6"
  }, [_vm._v("Anti-cheating Settings")]), _c('ul', {
    staticClass: "text-body-1"
  }, _vm._l(_vm.antiCheats, function (t) {
    return _c('li', {
      key: t
    }, [_vm._v(" " + _vm._s(t) + " ")]);
  }), 0)])], 1), _vm.testByUrlEnabled ? _c('v-row', [_c('v-col', {
    staticClass: "d-flex justify-space-between",
    attrs: {
      "cols": "12"
    }
  }, [_c('div', [_c('span', {
    staticClass: "text-h6"
  }, [_vm._v(" Test Invite URL: ")]), _c('a', {
    staticClass: "text-body-1"
  }, [_vm._v(" " + _vm._s(_vm.testUrl) + " ")])]), _c('v-btn', {
    attrs: {
      "large": "",
      "text": "",
      "outlined": ""
    },
    on: {
      "click": _vm.copyTestUrlToClipboard
    }
  }, [_c('v-icon', {
    attrs: {
      "left": "",
      "color": "success"
    }
  }, [_vm._v(" " + _vm._s(_vm.mdiClipboard) + " ")]), _c('span', {
    staticClass: "success--text"
  }, [_vm._v(" Copy URL ")])], 1)], 1)], 1) : _vm._e(), _vm.testByUrlEnabled ? _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('div', [_c('span', {
    staticClass: "text-h6"
  }, [_vm._v(" Test Invite URL Expiry: ")]), _c('span', {
    staticClass: "text-h6"
  }, [_vm._v(" " + _vm._s(_vm.testUrlExpiry) + " ")])])])], 1) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }