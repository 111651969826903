var render = function () {
  var _vm$assessment$getTit, _vm$assessment;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card', {
    attrs: {
      "outlined": "",
      "rounded": "lg",
      "elevation": "0",
      "loading": "".concat(_vm.fetchAssessmentStatus)
    }
  }, [_c('v-card-title', {
    staticClass: "text-h5 font-weight-bold"
  }, [_c('v-btn', {
    staticClass: "px-n3",
    attrs: {
      "color": "#122333"
    },
    on: {
      "click": _vm.handleBack
    }
  }, [_c('v-icon', {
    attrs: {
      "color": "white"
    }
  }, [_vm._v(" " + _vm._s(_vm.mdiArrowLeft) + " ")])], 1), _c('div', {
    staticClass: "ml-5"
  }, [_vm._v(" " + _vm._s((_vm$assessment$getTit = (_vm$assessment = _vm.assessment) === null || _vm$assessment === void 0 ? void 0 : _vm$assessment.getTitle()) !== null && _vm$assessment$getTit !== void 0 ? _vm$assessment$getTit : "Loading ...") + " ")])], 1), _c('v-card-text', {
    staticClass: "pa-0"
  }, [_c('div', {
    staticClass: "mb-8 px-5"
  }, [_c('v-expansion-panels', {
    model: {
      value: _vm.infoPanel,
      callback: function callback($$v) {
        _vm.infoPanel = $$v;
      },
      expression: "infoPanel"
    }
  }, [_c('v-expansion-panel', [_c('v-expansion-panel-header', {
    staticClass: "text-h6"
  }, [_vm._v(" Test Info ")]), _c('v-expansion-panel-content', [_vm.fetchAssessmentStatus > 0 ? _c('AssessmentDetails') : _vm._e()], 1)], 1)], 1)], 1), _c('candidates', {
    attrs: {
      "displayCandidates": _vm.displayCandidates,
      "candidateTableLoading": _vm.candidateTableLoading,
      "tableType": "TEST"
    },
    on: {
      "deleteMultipleCandidates": function deleteMultipleCandidates(toDeleteEmails) {
        return _vm.deleteMultipleCandidates(toDeleteEmails);
      },
      "handleCandidate": function handleCandidate(row) {
        return _vm.handleCandidate(row);
      },
      "returnToAllAssessment": _vm.returnToAllAssessment,
      "removeCandidate": function removeCandidate(item) {
        return _vm.removeCandidate(item);
      }
    }
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }